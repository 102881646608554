<template>
    <div>
        <div class="header">
            <span class="header_txt">勋章个人详情</span>
        </div>
        <div class="header_box">
            <div class="left">
                <button class="header_btn" @click="returnUrl">返回</button>
                <button class="header_btn" @click="update">更新</button>
                <button class="header_btn" @click="download">下载导出结果</button>
            </div>
            <div class="right"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row }" slot="portrait">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                        <img v-if="row.portrait" :src="row.portrait" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{ row, index }" slot="type">
                    <span class="infoModal_text" @click="receive(row)">领取记录</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 领取人查看弹框 -->
        <Modal v-model="receiveModal" title="领取用户" width="50%">
            <div class="detailsModal_content">
                <Table :columns="receiveTable" :data="receiveDataTable" border :no-data-text="noDataText"></Table>
            </div>
            <div class="footer">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
import { liveCustomerRecordsExport, liveCustomerRecords, liveCustomerXiongzhangList } from '@/api/index'
    export default {
        data() {
            return {
                //table
                liveTable: [
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '昵称',key: 'nickname',align:'center'},
                    {title: '手机号',key: 'phone',align:'center'},
                    {title: '领取数量',key: 'receive_num',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [
                    // { portrait: '',nickname: '', phone: '', receive_num: ''}
                ],
                //page
                total: 10,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                totalModal: 10,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    id: 1
                },
                receiveModal:false,
                receiveTable: [
                    {title: '领取时间', key: 'created_at'}
                ],
                receiveDataTable: [
                    // {created_at: ''}
                ],
                noDataText:'暂无数据',
            }
        },
        mounted(){
            this.liveCustomerRecords()
        },
        methods: {
            // 个人领取徽章详情
            liveCustomerRecords(){
                liveCustomerRecords(this.storePrams).then(res => {
                    console.log('个人领取徽章详情', res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.liveCustomerRecords()
            },
            receive(e){
                // 个人领取徽章的领取记录
                this.storePramsModal.id = e.id
                liveCustomerXiongzhangList(this.storePramsModal).then(res => {
                    console.log('个人领取徽章的领取记录', res.data.data)
                    this.totalModal = res.data.total
                    this.receiveTable = res.data.data
                })
                this.receiveModal = true
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            // 返回
            returnUrl(){
                this.$router.back()
            },
            // 更新
            update(){
                this.liveCustomerRecords()
            },
            // 导出
            download(){
                // 调列表接口
                liveCustomerRecordsExport().then(res=>{
                    window.open(res.data)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin: 10px 0;
}
.header_btn{
    padding: 8px 20px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
::v-deep .ivu-input.ivu-input-default{
    height: 40px;
}
</style>